import axios from "axios";

//axios.defaults.withCredentials = true;
export async function registerPerson(name, email, contact)
{
        console.log(email);
        const config = {
            headers: {
            'Content-Type': 'application/json'
            },
           // withCredentials: true
        };
        const data = {
            name: name,
            email: email,
            contact: contact
        }
        try {
            const response = await axios.post('https://meet-and-juice-backend.onrender.com/register', data, config);
            console.log("Flemme");
            if (response.status === 200)
                return "YES";
        } catch(error){
            return error.response.data.err
        }
}


export async function Subscribe(email)
{
        console.log(email);
        const config = {
            headers: {
            'Content-Type': 'application/json'
            },
           // withCredentials: true
        };
        const data = {
            email: email,
        }
        try {
            const response = await axios.post('https://meet-and-juice-backend.onrender.com/susbscribe', data, config);
            console.log("Flemme");
            if (response.status === 200)
                return "YES";
        } catch(error){
            return error.response.data.err
        }
}

export async function getUsers()
{
    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        // withCredentials: true
    };
    try{
        const response = await axios.get('https://meet-and-juice-backend.onrender.com/getAllregisters', config);
        if (response.status === 200)
            return response.data.users;
    } catch(error){
        console.log(error);
    }
}

export async function ConnectAdmin(email, password)
{
        console.log(email);
        const config = {
            headers: {
            'Content-Type': 'application/json'
            },
           // withCredentials: true
        };
        const data = {
            email: email,
            password: password
        }
        try {
            const response = await axios.post('https://meet-and-juice-backend.onrender.com/checkAdmin', data, config);
            console.log("Flemme");
            if (response.status === 200)
                return "YES";
        } catch(error){
            return error.response.data.err
        }
}