import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css'
import './tools.css'
 import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import CorePage from './CorePage';
import ConnexionPage from './ConnexionPage';
import GaleryPage from './Galery';
import ThanksPage from './Thanks';
import AdminPage from './Admin';

function App() {
  return (
     <Router>
       <Routes>
       <Route path='/connexion' element={<ConnexionPage></ConnexionPage>}></Route>
       <Route exact path='/' element={<CorePage></CorePage>}></Route>
       <Route  path='/galery' element={<GaleryPage></GaleryPage>}></Route>
       <Route  path='/thanks' element={<ThanksPage></ThanksPage>}></Route>
       <Route  path='/adminPanel' element={<AdminPage></AdminPage>}></Route>
       </Routes>
     </Router>
        );
      
}

export default App;
