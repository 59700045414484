import React from "react";
import { useState } from 'react';
 import { useForm } from 'react-hook-form';
import {Col, Row, Card, Container, Form, Alert, Button } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import background from './movies/fond violet.png'
import backgroundFormular from './movies/dégradé violet bleu.png'
import 'animate.css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoMeet from './movies/LOGO MEET & JUICE-02-02 (1).png'
import tiktok from './movies/tiktok-xxl.png'
import facebook from './movies/m2i8H7G6H7N4K9K9.png'
import linkdin from './movies/linkedin-app-white-icon.png'
import tweeter from './movies/x-social-media-white-icon.png'
import instagram from './movies/Logo-instagram-png-blanc.png'
import './tools.css'
import { registerPerson } from "./api_routes/login";
import fire from './movies/6.gif'
import { Helmet } from "react-helmet";



function ConnexionPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { register, handleSubmit, formState: { errors } } = useForm();
   const [error, setError] = useState('');
  const [isRequest, setRequest] = useState(false);
  const onSubmit = async (data) => {
       setRequest(true);
       let response = await registerPerson(data.name, data.email, data.contact);
       setRequest(false);
         if (response === "YES") {
          window.location.href = '/thanks';
       } else setError(response);
    };
    if (!isRequest) {
  return (
    <Container fluid className="no-padding" style={{overflowX: 'hidden', backgroundColor: 'white'}}>
        <Row>
        <Col>
        <Helmet>
            <title>Inscription au Meet And Juice</title>
            <meta name="description" content="Ceci est la page d'inscription du site officiel du Meet and Juice" />
            <meta name="keywords" content="meet and juice" />
          </Helmet>
          <Card ref={ref} className="full-screen5  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent'}}>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={background} alt="general" className='video-background'></img>
              <Button href="/" style={{width: '6.5em', height: '6.5em', marginTop: '0%', alignSelf: 'flex-start'}}>
              <img  src={logoMeet} className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`}  alt="general" style={{width: '6.5em', height: '6.5em'}}></img>
              </Button>
              <Card className="formular_card" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
              <img src={backgroundFormular} alt="general" className='video-background'></img>
              <Form style={{display: 'flex', flexDirection: 'column ', justifyContent: 'center', alignItems: 'center', height: '100%'}} className={`animate__animated ${inView ? 'animate__fadeInUp': ''}` } onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formBasicEmail" className="d-flex align-items-center" style={{width: '60%'}}>
                <Form.Label style={{color: 'white', marginRight: '5%', marginTop: '2em'}}>Nom: </Form.Label>
                <Form.Control className="form_input" type="text" placeholder="" {...register("name", { required: true })}/>
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="d-flex align-items-center" style={{width: '60%'}}>
                <Form.Label style={{color: 'white', marginRight: '5%', marginTop: '2em'}}>Email:</Form.Label>
                <Form.Control className="form_input" type="email" placeholder="" {...register("email", { required: true })}/>
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="d-flex align-items-center" style={{width: '60%'}}>
                <Form.Label style={{color: 'white', marginRight: '5%', marginTop: '2em'}}>Contact: </Form.Label>
                <Form.Control className="form_input" type="text" placeholder="" {...register("contact", { required: true })}/>
                {error && <Alert variant="danger">{error}</Alert>}
              </Form.Group>
              <Button type="submit"  variant="outline-light" style={{ borderColor: 'transparent',
                justifyContent: 'center', alignItems: 'center',  backgroundColor:'white', color: 'black', borderRadius: 30, display: 'flex', flexDirection: 'column',
                fontFamily: 'Myfont1', fontSize: '130%', marginTop: '2%'}}>
                Soumettre
                </Button>
              </Form>
              </Card>
              <Card.Text className={`meet_con  animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{alignSelf: 'self-start', fontFamily: 'Myfont1', fontSize: '120%'}}>Meet & Juice</Card.Text>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
           <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.tiktok.com/@meetandjuice?_t=8mcbZyQbZwr&_r=1'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '2em'}}>
            <img src={tiktok} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.facebook.com/meetandjuice.co?mibextid=LQQJ4d'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '3em'}}>
            <img src={facebook} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.linkedin.com/showcase/meet-and-juice/'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '3em'}}>
            <img src={linkdin} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://x.com/meetandjuice?s=21&t=XqZiZUMyY22RFMG2LZnfow'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '2.5em'}}>
            <img src={tweeter} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.instagram.com/meetandjuice?igsh=MTRoampzcW55MmFxZA=='variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width:'2.5em'}}>
            <img src={instagram} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
            </div>
           <Card className="copy_right" style={{width: '70%', alignSelf: 'center'}}></Card>
           <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`}style={{alignSelf: 'center', marginTop: '1%', fontWeight: 'bold', textAlign:'center'}}>Copyright  cO International 2024</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Container>
    );
  } else {
      return (
        <Container fluid className="no-padding" style={{overflowX: 'hidden', backgroundColor: 'white'}}>
        <Row>
        <Col>
          <Card ref={ref}  className="full-screen6  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent'}}>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',}}>
            <img src={background} alt="general" className='video-background'></img>
            <Card style={{width: '50%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'transparent', border: 0}}>
            <img src={fire} alt="general" className='video-background'></img>
            </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Container>
      );
  }
}

export default ConnexionPage;