import {Row, Col, Card, Button, Form, Alert} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import { useInView } from 'react-intersection-observer';
import background from './movies/fond violet.png'
import tiktok from './movies/tiktok-xxl.png'
import facebook from './movies/m2i8H7G6H7N4K9K9.png'
import linkdin from './movies/linkedin-app-white-icon.png'
import tweeter from './movies/x-social-media-white-icon.png'
import instagram from './movies/Logo-instagram-png-blanc.png'
import { useState } from 'react';
 import { useForm } from 'react-hook-form';
import { Subscribe } from './api_routes/login';
function FootPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState('');
  const [sucess, setSucess] = useState(false);
  const onSubmit = async (data) => {
      let response = await Subscribe(data.email);
      if (response !== 'YES') {
        setError(response);
        setSucess(false);
      }
      else {
        setError('Félicitation vous êtes enregistrer.');
        setSucess(true);
      }
    };

  return (
      <>
        <Row>
        <Col>
          <Card ref={ref}  className="foot_page" style={{borderRadius: 0, color: 'white', borderColor: 'transparent', backgroundColor: '#2C1E4A'}}>
          <img src={background} alt="general" className='video-background'></img>
            <Card.Body className='foot_main_div' style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column',}}>
            <div className='foot_first_div' style={{display: 'flex'}}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button onClick={() => scrollToSection('page1')} className={`link animate__animated ${inView ? 'animate__fadeInUp': ''}`}   style={{textDecoration: 'underline', height: '1.5em', background: 'transparent', fontSize: '110%', borderColor: 'transparent', fontWeight: 'bold'}}>
                Meet And Juice
              </Button>
              <Button onClick={() => scrollToSection('page2')} className={`link animate__animated ${inView ? 'animate__fadeInUp': ''}`}   style={{textDecoration: 'underline', height: '1.5em', marginTop: '1%', background: 'transparent', fontSize: '110%', borderColor: 'transparent', fontWeight: 'bold'}}>
                A propos
              </Button>
              <Button onClick={() => scrollToSection('page3')} className={`link animate__animated ${inView ? 'animate__fadeInUp': ''}`}   style={{textDecoration: 'underline', height: '1.5em', background: 'transparent', fontSize: '110%', borderColor: 'transparent', fontWeight: 'bold'}}>
                La VIBE
              </Button>
              <Button onClick={() => scrollToSection('page4')} className={`link animate__animated ${inView ? 'animate__fadeInUp': ''}`}   style={{textDecoration: 'underline' ,height: '2em', background: 'transparent', fontSize: '110%', borderColor: 'transparent', fontWeight: 'bold'}}>
                Au programme
              </Button>
              <Button href='/connexion' className={`link animate__animated ${inView ? 'animate__fadeInUp': ''}`}   style={{textDecoration: 'underline' ,height: '2em', background: 'transparent', fontSize: '110%', borderColor: 'transparent', fontWeight: 'bold'}}>
                S'inscrire
              </Button>
           </div>
           <div className='foot_second_div' style={{display: 'flex', flexDirection: 'column',}}>
           <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{fontWeight: 'bold', fontSize: '120%', marginTop: '5%'}}>CONTACTEZ-NOUS</Card.Text>
           <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{fontWeight: 'bold', fontSize: '120%'}}>Téléphone:  +229 99 52 12 12</Card.Text>
           <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{fontWeight: 'bold', fontSize: '120%'}}>email: meetandjuice@gmail.com</Card.Text>
           </div>
           <div className='foot_second_div' style={{display: 'flex', flexDirection: 'column',}}>
           <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{fontWeight: 'bold', fontSize: '130%', textAlign: 'center'}}>Restez informer de toute notre actualité!</Card.Text>
           <Form  style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1vw'}} className={`animate__animated ${inView ? 'animate__fadeInUp': ''}` } onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formBasicEmail" className="d-flex align-items-center" style={{}}>
                <Form.Label style={{color: 'white', marginRight: '5%'}}>Email: </Form.Label>
                <Form.Control   style={{borderRadius: 0, background: 'transparent', color: 'white', outline: 'none'}} type="email" placeholder="" {...register("email", { required: true })}/>
                {error && <Alert variant={`${sucess ? 'success': 'danger'}`}>{error}</Alert>}
              </Form.Group>
              <Button type="submit"  variant="outline-light" style={{ borderColor: 'white', backgroundColor: 'transparent',
                justifyContent: 'center', alignItems: 'center', color: 'white', display: 'flex', flexDirection: 'column', borderRadius: 0,
                fontSize: '100%', width: '5em'}}>
                S'inscrire
                </Button>
              </Form>
              <div className='reseau' style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '5%'}}>
           <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.tiktok.com/@meetandjuice?_t=8mcbZyQbZwr&_r=1'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '2em'}}>
            <img src={tiktok} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.facebook.com/meetandjuice.co?mibextid=LQQJ4d'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '3em'}}>
            <img src={facebook} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.linkedin.com/showcase/meet-and-juice/'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '3em'}}>
            <img src={linkdin} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://x.com/meetandjuice?s=21&t=XqZiZUMyY22RFMG2LZnfow'variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width: '2.5em'}}>
            <img src={tweeter} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
          <Button className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='https://www.instagram.com/meetandjuice?igsh=MTRoampzcW55MmFxZA=='variant="outline-light" style={{display: 'flex', borderColor: 'transparent',
            justifyContent: 'center', alignItems: 'center',backgroundColor: 'transparent', color: 'white', width:'2.5em'}}>
            <img src={instagram} alt="general" style={{height:'auto', width: '2em'}}></img> 
          </Button>
            </div>
           </div>
              </div>
              <Card style={{marginTop: '5%', width: '70%', alignSelf: 'center'}}></Card>
              <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`}style={{alignSelf: 'center', marginTop: '3%', fontWeight: 'bold', textAlign:'center', fontSize: '120%'}}>Copyright  cO International 2024</Card.Text>
              
            </Card.Body> 
          </Card>
          </Col>
          </Row>
      </>
  );
}

export default FootPage;