  import {Row, Col, Card, Button} from 'react-bootstrap';
  import './tools.css'
  import logoMeet from './movies/LOGO MEET & JUICE-02-02 (1).png'
  // import backgroundGif from './movies/Design sans titre (1).gif'
  import backgroundVideo from './movies/background.mp4'
  import { Helmet } from 'react-helmet';
  import 'animate.css'

  function HomePage() {
    
    return (
        <>
          <Row>
          <Col>
          <Helmet>
            <title>Meet and Juice accueil</title>
            <meta name="description" content="Ceci est la page d'accueil du site officiel du Meet and Juice" />
            <meta name="keywords" content="meet and juice" />
          </Helmet>
            <Card id='page1' className="full-screen  no-padding" style={{scrollBehavior: 'smooth', background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent'}}>
              <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <video src={backgroundVideo} autoPlay loop muted playsInline className="video-background" style={{ borderColor: 'transparent', pointerEvents: 'none'}}>
                  Your browser does not support the video tag.
                  </video>
            {/* <img src={backgroundGif} alt="general" className='video-background'></img> */}
            <img src={logoMeet} alt="general" className='logo_Meet animate__animated animate__flip'></img>
            <Card.Text className='animate__animated animate__fadeInUp' style={{top: '-5%', marginTop: '-5%', fontWeight: 'bold', fontSize: '140%', position: 'relative'}} >1er Juin 2024</Card.Text>
              <Button href='/connexion' variant="outline-light" style={{position: 'relative', width: '10em', height: '3em', borderColor: 'transparent',
                justifyContent: 'center', alignItems: 'center', fontSize: '130%', fontFamily: 'Myfont1' ,backgroundColor:'white', color: 'black', borderRadius: 30, display: 'flex', flexDirection: 'column'}}>
                S'inscrire
              </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </>
    );
  }

export default HomePage;