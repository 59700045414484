import {Row, Col, Card,Button} from 'react-bootstrap';
import './tools.css'
import logoMeet from './movies/LOGO MEET & JUICE-02-02 (1).png'
import background from './movies/dégradé violet bleu.png'
import 'animate.css'
import img1 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.19 (4).jpeg'
import img2 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (1).jpeg'
import img3 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20.jpeg'
import img4 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (2).jpeg'
import img5 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (3).jpeg'
import img6 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.21 (3).jpeg'
import img7 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.21.jpeg'
import img8 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.19 (3).jpeg'
import img9 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.21 (1).jpeg'
import img10 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.22.jpeg'
import img11 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.22 (2).jpeg'
import img12 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.22 (4).jpeg'
import img13 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.23 (1).jpeg'
import img14 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.23.jpeg'
import img15 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.17 (3).jpeg'
import img16 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.17.jpeg'
import img17 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.23 (2).jpeg'
import img18 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.19 (1).jpeg'
import img19 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.18.jpeg'
import img20 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.18 (1).jpeg'
import img21 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.22 (3).jpeg'
import img22 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.21 (2).jpeg'

function GaleryPage() {
  
  return (
      <>
        <Row>
        <Col>
          <Card className="full-screen6  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent', }}>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll', gap: '1em'}}>
            <img src={background} alt="general" className='video-background'></img>
              <Button href="/" style={{width: '6.5em', height: '6.5em', marginTop: '1%', alignSelf: 'flex-start'}}>
              <img  src={logoMeet} className='animate__animated animate__flip'  alt="general" style={{width: '6.5em', height: '6.5em'}}></img>
              </Button>
            <Card.Text className='animate__animated animate__fadeInUp'  style={{fontSize: '150%', fontFamily: 'Myfont1'}}>Édition 2023</Card.Text>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp' style={{width: '20%', height: '15vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img1} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '25%', height: '15vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img2} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '30%', height: '15vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img3} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp'style={{width: '25%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img4} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '25%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img5} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '25%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img6} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div className='animate__animated animate__fadeInUp'style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp'  style={{width: '30%', height: '17vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img7} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '15%', height: '17vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img8} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '30%', height: '17vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img9} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp' style={{width: '40%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img10} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '35%', height: '25vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img11} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp' style={{width: '40%', height: '25vw', borderRadius: 0, backgroundColor: 'transparent', top: '-5vw'}}>
          <img src={img12} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '22%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img13} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '13%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img14} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em', top: '-5vw', position: 'relative'}}>
          <Card  className='animate__animated animate__fadeInUp' style={{width: '30%', height: '20vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img15} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '47%', height: '33vw', borderRadius: 0, backgroundColor: 'transparent',}}>
          <img src={img16} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp' style={{width: '30%', height: '17vw', borderRadius: 0, backgroundColor: 'transparent', top: '-17.5vw'}}>
          <img src={img17} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '47%', height: '40vw', borderRadius: 0, backgroundColor: 'transparent',top: '-5vw'}}>
          <img src={img18} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em'  }}>
          <Card className='animate__animated animate__fadeInUp' style={{width: '30%', height: '34vw', borderRadius: 0, backgroundColor: 'transparent', left: '-24%', top: '-40vw'}}>
          <img src={img19} alt="general" className='video-background'></img>
          </Card>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center',gap: '1em', marginTop: '-40vw'}}>
          <Card className='animate__animated animate__fadeInUp'style={{width: '25%', height: '25vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img20} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp'style={{width: '27%', height: '35vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img21} alt="general" className='video-background'></img>
          </Card>
          <Card className='animate__animated animate__fadeInUp' style={{width: '22%', height: '35vw', borderRadius: 0, backgroundColor: 'transparent'}}>
          <img src={img22} alt="general" className='video-background'></img>
          </Card>
          </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
  );
}

export default GaleryPage;