import {Row, Col, Card, Button} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import { useInView } from 'react-intersection-observer';
import background from './movies/dégradé bleu violet.png'
import picture1 from './movies/anim2.gif'
import img1 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.21 (2).jpeg'
import img2 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (1).jpeg'
import img3 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (4).jpeg'
import img4 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20.jpeg'
import picture2 from './movies/anim3.gif'


function EditionImagePage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    
      <>
        <Row>
        <Col>
          <Card ref={ref} id='page5' className="full-screen4" style={{scrollBehavior: 'smooth', borderRadius: 0, color: 'white', borderColor: 'transparent', backgroundColor: 'transparent'}}>
          <img src={background} alt="general" className='video-background'></img>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Card.Title className={`title animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{marginTop: '1%', fontFamily: 'Myfont1'}}>MEET & JUICE 1</Card.Title>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '100%', zIndex: 0, display: 'flex', flexDirection: 'row', gap: '10vw', justifyContent: 'center', alignItems: 'center', marginTop: '-25%'}}>
              <Card className='picture' style={{background: 'transparent', border: 0}}>
              <img src={picture1} alt="general" className='video-background'></img>
              </Card>
              <Card className='picture' style={{background: 'transparent', border: 0}}>
              <img src={picture2} alt="general" className='video-background' ></img>
              </Card>
              </div>
              <div className='img_div' style={{width: '100%', zIndex: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <div className='img' style={{ backgroundColor: 'red', borderRadius: '50%', position: 'relative', overflow: 'hidden' }}>
                    <img src={img1} alt="general" className='video-background'></img>
                  </div>
                  <div className='img2' style={{backgroundColor: 'red', borderRadius: '50%', position: 'relative', overflow: 'hidden', marginLeft: '-5%', top: '30%' }}>
                  <img src={img2} alt="general" className='video-background'></img>
                  </div>
                  <div className='img3' style={{ backgroundColor: 'red', borderRadius: '50%', position: 'relative', overflow: 'hidden', marginLeft: '-5%', zIndex: 2 }}>
                  <img src={img4} alt="general" className='video-background'></img>
                  </div>
                  <div className='img' style={{ backgroundColor: 'red', borderRadius: '50%', position: 'relative', overflow: 'hidden', marginLeft: '-5%' }}>
                    <img src={img3} alt="general" className='video-background'></img>
                  </div>
                </div>
                <Button className={` discovery animate__animated ${inView ? 'animate__fadeInUp': ''}`} href='/galery' variant="outline-light" style={{position: 'relative'  , borderColor: 'transparent',
                justifyContent: 'center', alignItems: 'center', fontSize: '105%', fontFamily: 'Myfont1' ,backgroundColor:'white', color: 'black', borderRadius: 30, display: 'flex', flexDirection: 'column',}}>
                Voir plus 
              </Button> 
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
  );
}

export default EditionImagePage;