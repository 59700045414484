import {Row, Col, Card} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import { useInView } from 'react-intersection-observer';
import background from './movies/dégradé violet bleu.png'
import img1 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.19 (3).jpeg'
import img2 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.20 (3).jpeg'
import img3 from './movies/meat-and-juice-picture/femme.png'

function SummaryPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    
      <>
        <Row>
        <Col>
          <Card ref={ref}  id='page3' className="full-screen2" style={{scrollBehavior: 'smooth', borderRadius: 0, color: 'white', borderColor: 'transparent', backgroundColor: 'transparent'}}>
          <img src={background} alt="general" className='video-background'></img>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Card.Title className={`title animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{marginTop: '1%', fontWeight: 'bold'}}>LA VIBE</Card.Title>
            <Card.Text className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{marginTop: '1%',color: 'white', fontSize: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'}}>Echange entre les créateurs de contenu, leurs communautés et les marques pour stimuler la créativité et la collaboration.</Card.Text>
            <div className='div_style' style={{display: 'flex', justifyContent: 'center', height: '100%', width: '100%'}}>
                <div className={`animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <Card className='cadre_photo' style={{width: '20em', borderRadius: 0, backgroundColor: 'transparent'}}>
                <img src={img1} alt="general" className='video-background'></img>
                </Card>
                <Card className='card_title' style={{width: '20em', borderRadius: 0, justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Card.Title style={{fontFamily: 'Myfont1', color: '#D73CBE'}}>CONVIVIALITÉ</Card.Title>
                <Card.Text style={{color: 'black', textAlign: 'center'}}>Une atmosphère positive, dynamique et inspirante</Card.Text>
                </Card>
                </div>
                <div className={`card_style animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <Card  className='cadre_photo' style={{width: '20em',borderRadius: 0, backgroundColor: 'transparent'}}>
                <img src={img2} alt="general" className='video-background'></img>
                </Card>
                <Card className='card_title' style={{width: '20em', borderRadius: 0, justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Card.Title style={{fontFamily: 'Myfont1', color: '#D73CBE'}}>ÉCHANGES</Card.Title>
                <Card.Text style={{color: 'black', textAlign: 'center'}}>Une expérience mémorable et enrichissante pour tous les participants</Card.Text>
                </Card>
                </div>
                <div className={`card_style animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <Card  className='cadre_photo' style={{width: '20em', borderRadius: 0}}>
                <img src={img3} alt="general" className='video-background'></img>
                </Card>
                <Card className='card_title' style={{width: '20em', borderRadius: 0,  justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Card.Title style={{fontFamily: 'Myfont1', color: '#D73CBE'}}>PARTAGE</Card.Title>
                <Card.Text style={{color: 'black', textAlign: 'center',}}>Promotion des produits et services des marques de manière authentique et engageante</Card.Text>
                </Card>
                </div>
            </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
  );
}

export default SummaryPage;