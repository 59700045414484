import {Row, Col, Card} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import { useInView } from 'react-intersection-observer';
// import backgroundGif from './movies  /Design sans titre (1).gif'
import background from './movies/fond violet.png'
import img1 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-27 at 10.42.57 (1).jpeg'
import img3 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.19 (1).jpeg'
import img2 from './movies/meat-and-juice-picture/WhatsApp Image 2024-05-23 at 15.54.17.jpeg'

function QuestionsPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    
      <>
        <Row>
        <Col>
          <Card ref={ref}  id='page4' className="full-screen3" style={{scrollBehavior: 'smooth',borderRadius: 0, color: 'white', borderColor: 'transparent', backgroundColor: 'transparent'}}>
          { <img src={background} alt="general" className='video-background'></img> }
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Card.Title className={`title animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{ fontFamily: 'Myfont1', marginBottom: '2%'}}>Au Programme</Card.Title>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%', gap: '1vw', justifyContent: 'center', alignItems: 'center'}}>
            <Card  className={`prog_img animate__animated ${inView ? 'animate__fadeInLeft': ''}`}  style={{background: 'transparent'}}>
              <img src={img1} alt="general" className='video-background'></img>
            </Card>
            <Card className={`prog_img animate__animated ${inView ? 'animate__fadeInUp': ''}`}style={{background: 'transparent'}}>
              <img src={img2} alt="general" className='video-background'></img>
            </Card>
            <Card className={`prog_img animate__animated ${inView ? 'animate__fadeInRight': ''}`} style={{background: 'transparent'}}>
              <img src={img3} alt="general" className='video-background'></img>
            </Card>
            </div>
            <div className='prog_main0' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <div className='prog_main_div1' style={{display: 'flex', alignItems: 'center'}}>
                <div className='' style={{flexDirection: 'column', height: '100%', alignItems: 'center', width: '100%'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>9H 00 - 9H 30 :</Card.Text>
              <Card.Text  style={{}}className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Accueil des participants et enregistrement</Card.Text>
                </div>
                <div className='' style={{flexDirection: 'column', height: '100%', alignItems: 'center', width: '100%'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>9H 30 - 10H 00 :</Card.Text>
              <Card.Text  style={{}}className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Discours d'ouverture par Mister Juicy</Card.Text>
                </div>
                <div className='' style={{flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>10H 00 - 11H :</Card.Text>
              <Card.Text  style={{}}className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Conférence sur l'importance du marketing d'influence dans l'industrie médiatique moderne</Card.Text>
                </div>
                <div className='' style={{flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>11H 00 - 12H 00  :</Card.Text>
              <Card.Text  style={{}} className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Panel de discussions avec des experts du marketing d'influence et des créateurs de contenu à succès</Card.Text>
                </div>
              </div>
              <div className='prog_main_div2' style={{display: 'flex'}}> 
                <div className='' style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>12H 00 - 13H 00  :</Card.Text>
              <Card.Text  style={{}}className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Collation et networking</Card.Text>
                </div>
                <div className='' style={{flexDirection: 'column', height: '100%', width: '100%'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>13H 00 - 14H 00   :</Card.Text>
                <Card.Text  style={{}}className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Présentations de marques partenaires et démonstrations de produits et services</Card.Text>
                </div>
                <div className='' style={{flexDirection: 'column', height: '100%', width: '100%'}}>
                <Card.Text  style={{ fontWeight: 'bold', whiteSpace: 'nowrap'}} className={`prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>14H00-16H30 :</Card.Text>
                <Card.Text style={{width: '100%'}} className={`prog1 prog animate__animated ${inView ? 'animate__fadeInUp': ''}`}>Rencontre avec les créateurs de contenu, séances de dédicaces, match de Gala, opportunités de photos, ambiance DJ</Card.Text>      
                </div>
              </div>
            </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
  );
}

export default QuestionsPage;