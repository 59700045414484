import {Container} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import HomePage from './HomePage';
import DescriptionPage from './DescriptionPage';
import SummaryPage from './Summary';
import QuestionsPage from './Questions';
import EditionImagePage from './Images';
import FootPage from './FootPage';
import CookieConsent from 'react-cookie-consent';

function CorePage() {
  

  return (
    <Container fluid className="no-padding" style={{overflowX: 'hidden', backgroundColor: 'white'}}>
         <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        enableDeclineButton
        declineButtonText="Je refuse"
        cookieName="CookieConsent"
        style={{ background: "white", color: '#2C1E4A', textAlign: 'center', padding: '20px', fontSize: '16px' }}
        buttonStyle={{ color: 'white', backgroundColor: '#371F4D', fontSize: '14px', borderRadius: '5px' }}
        declineButtonStyle={{ color: 'white', backgroundColor: '#371F4D', fontSize: '14px', borderRadius: '5px'}}
        expires={150}
        onAccept={() => {
          console.log("Cookies accepted!");
        }}
        onDecline={() => {
          console.log("Cookies declined!");
        }}
      >
        Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. Si vous continuez à utiliser ce site nous supposerons que vous en êtes satisfait.
      </CookieConsent>
    <HomePage></HomePage>
    <DescriptionPage></DescriptionPage>
    <SummaryPage></SummaryPage>
    <QuestionsPage></QuestionsPage>
    <EditionImagePage></EditionImagePage>
    <FootPage></FootPage>
  </Container>
  );
}

export default CorePage;