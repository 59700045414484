import {Row, Col, Card} from 'react-bootstrap';
import './tools.css'
import 'animate.css'
import { useInView } from 'react-intersection-observer';
import background from './movies/dégradé violet bleu.png'
import jus from './movies/anim1.gif'
import date from './movies/date.png'
import lieu from './movies/lieu.png'
import time from './movies/heure.png'

// let description = 'Le Meet & Juice est un événement dynamique qui réunit créateurs de contenu, marques et communautés pour des échanges enrichissants et créatifs. Reconnaissant l\'influence croissante des créateurs dans le paysage médiatique, cet événement exploite les réseaux sociaux et les plateformes en ligne pour développer des communautés passionnées.\
//  Il facilite la collaboration entre marques et créateurs, offrant une exposition authentique des produits et des opportunités mutuelles de croissance.'

function DescriptionPage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
      <>
        <Row>
        <Col>
          <Card id='page2' ref={ref}  className="full-screen1" style={{scrollBehavior: 'smooth', borderRadius: 0, color: 'white', borderColor: 'transparent', backgroundColor: 'transparent'}}>
          <img src={background} alt="general" className='video-background'></img>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '3vw'}}>
            <Card  className='card_page1' style={{ backgroundColor: 'transparent', borderColor:'transparent', }}>
            <img src={jus} alt="general" className='video-background'></img>
            </Card>
            <Card className='card_page2 ' style={{backgroundColor: 'transparent', borderColor:'transparent', color: 'white', display: 'flex', flexDirection: 'column'}}>
                <Card.Title className={`title animate__animated ${inView ? 'animate__fadeInUp': ''}`} style={{marginTop: '1%', fontFamily: 'Myfont1', width: '8em'  }}>Y’a du jus dans Cotonou !</Card.Title>
                <Card.Text className={`description animate__animated ${inView ? 'animate__fadeInUp': ''}`}>{'Le Meet & Juice est un événement dynamique qui réunit créateurs de contenu, marques et communautés pour des échanges enrichissants et créatifs. Reconnaissant l\'influence croissante des créateurs dans le paysage médiatique, cet événement exploite les réseaux sociaux et les plateformes en ligne pour développer des communautés passionnées.\
 \nIl facilite la collaboration entre marques et créateurs, offrant une exposition authentique des produits et des opportunités mutuelles de croissance.'}</Card.Text>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '5%', gap: '1em', marginLeft: '-0.5%'}}>
            <img src={date} alt="general" className='logo_indication' style={{}}></img>
                <Card.Text style={{}} className={`descr animate__animated ${inView ? 'animate__fadeInUp': ''}`}>1er Juin 2024</Card.Text>
              </div> 
              <div style={{display: 'flex', flexDirection: 'row',gap: '1.3em', marginTop: '1%'}}>
            <img src={time} alt="general" className='logo_indication2' style={{}}></img>
                <Card.Text style={{}} className={` descr animate__animated ${inView ? 'animate__fadeInUp': ''}`}>A partir de 09h00</Card.Text>
              </div>              
            <div style={{display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1%', marginLeft: '-0.5%'}}>
            <img src={lieu} alt="general" className='logo_indication'  style={{}}></img>
                <Card.Text style={{}} className={`descr animate__animated ${inView ? 'animate__fadeInUp': ''}`}>EYA Centre communautaire</Card.Text>
              </div> 

            </Card>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
  );
}

export default DescriptionPage;