import React from "react";
import { useState, useEffect } from 'react';
 import { useForm } from 'react-hook-form';
import {Col, Row, Card, Container, Form, Alert, Button, Table } from "react-bootstrap";
// import { utils, write } from "xlsx";
// import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';
import 'animate.css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoMeet from './movies/LOGO MEET & JUICE-02-02 (1).png'
import './tools.css'
  import backgroundVideo from './movies/background.mp4'
  import { ConnectAdmin, getUsers,} from "./api_routes/login";



function AdminPage() {
  const [data, setData] = useState([]);
  const [isConnected, setConnected] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
        const response = await getUsers();
        setData(response);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const exportToExcel = () => {
    const filteredData = data.map(({ name, email, contact }) => ({ name, email, contact }));

    // Convertir les données en feuille de calcul
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Ajuster la largeur des colonnes
    const columnWidths = [
      { wch: 40 }, // Largeur pour la colonne Nom
      { wch: 40 }, // Largeur pour la colonne Email
      { wch: 35 }  // Largeur pour la colonne Contact
    ];

    worksheet['!cols'] = columnWidths;

    const rowHeights = [{ hpt: 25 }, { hpt: 25 }, { hpt: 25 }]; // Hauteur de 24 points pour chaque ligne
    worksheet['!rows'] = rowHeights;

    // Créer un nouveau classeur et ajouter la feuille de calcul
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Participants');
    // Générer le fichier Excel
    XLSX.writeFile(workbook, 'participants.xlsx');

  };

  const { register, handleSubmit, formState: { errors } } = useForm();
   const [error, setError] = useState('');
  const [isRequest, setRequest] = useState(false);
  const onSubmit = async (data) => {
       setRequest(true);
       let response = await ConnectAdmin(data.email, data.password);
       setRequest(false);
         if (response === "YES") {
          setConnected(true);
       } else setError(response);
    };
           
    if (!isRequest && isConnected) {
   return (
    <Container fluid className="no-padding" style={{overflow: 'hidden', backgroundColor: 'white'}}>
        <Row>
        <Col>
          <Card className="full-screen6  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1%', overflow: 'auto'}}>
            {/* <img src={background} alt="general" className='video-background'></img> */}
            <video src={backgroundVideo} autoPlay loop muted playsInline className="video-background" style={{ borderColor: 'transparent', pointerEvents: 'none'}}>
                  Your browser does not support the video tag.
                  </video>
              <Button href="/" style={{width: '6.5em', height: '6.5em', marginTop: '1%', alignSelf: 'flex-start', background: 'transparent'}}>
              <img  src={logoMeet} className='animate__animated animate__flip'  alt="general" style={{width: '6.5em', height: '6.5em'}}></img>
              </Button>
              <Card.Text className='animate__animated animate__fadeInUp'  style={{fontSize: '200%', fontWeight: 'bold', textAlign: 'center'}}>{data.length} participants inscrits</Card.Text>
             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%', width: '100%',}}>
              <Table className='animate__animated animate__fadeInUp'   hover responsive bordered  style={{ /*backgroundColor: 'transparent',*/ width: '10%', borderColor: 'black'}}>
                <thead style={{}}>
                  <tr>
                    <th style={{backgroundColor: '#ae4de5', color: 'white'}} >Nom</th>
                    <th style={{backgroundColor: '#ae4de5', color: 'white'}} >Email</th>
                    <th style={{backgroundColor: '#ae4de5', color: 'white'}} >Contact</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((participant, index) => (
                    <tr key={index}>
                      <td style={{/*backgroundColor: 'transparent',*/ color: '#2C1E4A', whiteSpace: 'nowrap'}} >{participant.name}</td>
                      <td style={{/*backgroundColor: 'transparent',*/ color: '#2C1E4A'}} >{participant.email}</td>
                      <td style={{/*backgroundColor: 'transparent',*/ color: '#2C1E4A'}}  >{participant.contact}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
             </div>
             <button  className='animate__animated animate__fadeInUp' onClick={exportToExcel} style={{ margin: '10px', padding: '10px', backgroundColor: 'green', color: 'white' }}>
                  Export to Excel
              </button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Container>
    );
  } else if (!isRequest) {
      return (
        <Container fluid className="no-padding" style={{overflowX: 'hidden', backgroundColor: 'white'}}>
        <Row>
        <Col>
          <Card   className="full-screen6  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent'}}>
            <Card.Body  style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}>
            <video src={backgroundVideo} autoPlay loop muted playsInline className="video-background" style={{ borderColor: 'transparent', pointerEvents: 'none'}}>
                  Your browser does not support the video tag.
                  </video>
              <Card.Text className='animate__animated animate__fadeInUp'  style={{fontSize: '200%', fontWeight: 'bold', textAlign: 'center', marginBottom: '4%'}}>Admin Dashboard</Card.Text>
                  <Form style={{}} className='form_questions animate__animated animate__fadeInUp'onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formBasicEmail" className="mb-3" style={{width: '100%'}} >
                <Form.Label style={{color: 'white', fontSize: '150%'}}>Email</Form.Label>
                <Form.Control style={{}} type="email" placeholder="Entrez votre email" {...register("email", { required: true })}/>
                {errors.email && <Alert variant="danger">Email requis</Alert>}
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mb-3">
                <Form.Label style={{color: 'white', fontSize: '150%'}}>Mot de passe</Form.Label>
                <Form.Control type="password" placeholder="Mot de passe" {...register("password", { required: true })} />
                {errors.password && <Alert variant="danger">Mot de passe requis</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
              </Form.Group>
              <Button variant="primary" type="submit" style={{backgroundColor: 'transparent', borderColor: 'white'}}>
                Connexion
              </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Container>
      );
  }
}

export default AdminPage;