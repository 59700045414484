import {Row, Col, Card, Button, Container} from 'react-bootstrap';
import './tools.css'
import logoMeet from './movies/LOGO MEET & JUICE-02-02 (1).png'
import background from './movies/fond violet.png'
import 'animate.css'
import checker from './movies/checker.png'

function ThanksPage() {

  return (
    <Container fluid className="no-padding" style={{overflowX: 'hidden', backgroundColor: 'white'}}>
        <Row>
        <Col>
          <Card className="full-screen6  no-padding" style={{ background: 'transparent', borderRadius: 0, color: 'white', borderColor: 'transparent',}}>
            <Card.Body style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
          <img src={background} alt="general" className='video-background'></img>
          <Button href="/" style={{width: '6.5em', height: '6.5em', alignSelf: 'self-start'}}>
              <img  src={logoMeet} className='animate__animated animate__flip'  alt="general" style={{width: '6.5em', height: '6.5em'}}></img>
              </Button>
              <Card className='animate__animated animate__fadeInUp' style={{width: '50%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'transparent', border: 0}}>
            <img src={checker} alt="general" className='video-background'></img>
            </Card>
            <Card.Text className='enregistrement animate__animated animate__fadeInUp' style={{fontWeight: 'bold', textAlign: 'center', marginTop: '-5%'}} >Nous vous remercions pour votre inscription</Card.Text>
            <Card.Text className='enregistrement animate__animated animate__fadeInUp' style={{fontWeight: 'bold', textAlign: 'center', color: '#AB99DC',}} >Votre participation pour la seconde édition du Meet & Juice  à bien été enregistrée :)</Card.Text>
            <Button className='animate__animated animate__fadeInUp' href='/' variant="outline-light" style={{position: 'relative', width: '10em', height: '3em', borderColor: 'transparent',
              justifyContent: 'center', alignItems: 'center', fontSize: '130%', fontFamily: 'Myfont1' ,backgroundColor:'white', color: 'black', borderRadius: 30, display: 'flex', flexDirection: 'column', marginTop: '5%'}}>
              Retour à la page d'acceuil
            </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Container>
  );
}

export default ThanksPage;